import MyRoutes from "./route/MyRoutes";
import TokenExpiredModal from "./components/Modals/TokenExpiredModal";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App()
{
    return (
        <>
            <ToastContainer closeOnClick={true} />
            <MyRoutes />
            <TokenExpiredModal />
        </>
    );
}

export default App;
