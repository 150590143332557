import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open: true,
    subMenu: null,
}

export const sidebarValue = createSlice({
    name: 'sidebarValue',
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setSubMenu: (state, action) => {
            state.subMenu = action.payload
        },
    },
})

export const { setOpen, setSubMenu } = sidebarValue.actions

export default sidebarValue.reducer