import * as Yup from 'yup';


export const loginValidation = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

export const leaderShipValidation = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    name: Yup.string().required('Name is required'),
});

export const changePasswordValidation = Yup.object({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
});

export const advertisementValidation = Yup.object({
    name: Yup.string().required('Name is required'),
});

export const calenderValidation = Yup.object({
    from: Yup.string().required('From field is required'),
    to: Yup.string().required('To field is required'),
});

export const emailConfigValidation = Yup.object({
    organization: Yup.string().required('Franchise Name is required'),
    // EinNo: Yup.string().required('IRS EIN is required'),
});