import React, { lazy } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense } from "react";
import Login from '../pages/Login';
import AdminPrivate from './adminPrivateRoute';
import Loader from '../components/Loader';
import { usePaymentAuth } from '../context/paymentContext';
// import EmailConfig from '../pages/EmailConfig';
// import DonorDetail from '../pages/DonorDetail';
// import ChangePassword from '../pages/ChangePassword';

const MyRoutes = () =>
{
    const token = window?.localStorage?.getItem("token") ? true : false
    const { paymentStatus } = usePaymentAuth()

    const Dashboard = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Dashboard")), 1300);
        });
    });

    // const PrayerRequest = lazy(() => {
    //     return new Promise((resolve) => {
    //         setTimeout(() => resolve(import("../pages/PrayerRequest")), 1300);
    //     });
    // });

    const Transaction = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Transaction")), 1300);
        });
    });


    // const Reports = lazy(() => {
    //     return new Promise((resolve) => {
    //         setTimeout(() => resolve(import("../pages/Reports")), 1300);
    //     });
    // });


    const ReligiousLeader = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Religiousleader")), 1300);
        });
    });

    const Kiosk = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Kiosk")), 1300);
        });
    });


    const ViewKioskTheme = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/ViewKioskTheme")), 1300);
        });
    });

    const TransactionDetail = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/TransactionDetail")), 1300);
        });
    });

    const Advertisements = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Advertisements")), 1300);
        });
    });


    const ManageAd = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/ManageAd")), 1300);
        });
    });

    const Donor = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Donor")), 1300);
        });
    });

    const DonorDetail = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/DonorDetail")), 1300);
        });
    });


    const ChangePassword = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/ChangePassword")), 1300);
        });
    });

    const EmailConfig = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/EmailConfig")), 1300);
        });
    });


    const Subscription = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Subscription")), 1300);
        });
    });

    const SubscriptionDetail = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/SubscriptionDetail")), 1300);
        });
    });

    const PaySubscription = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/PaySubscription")), 1300);
        });
    });

    const Subscribe = lazy(() =>
    {
        return new Promise((resolve) =>
        {
            setTimeout(() => resolve(import("../pages/Subscribe")), 1300);
        });
    });

    const allRoutes = [
        {
            path: "/dashboard",
            element: <Dashboard />
        },
        {
            path: "/prayerRequest",
            element: <Transaction />
        },
        {
            path: "/religiousLeader",
            element: <ReligiousLeader />
        },
        {
            path: "/kiosk",
            element: <Kiosk />
        },
        {
            path: "/manageAd",
            element: <ManageAd />
        },
        {
            path: "/viewKioskTheme",
            element: <ViewKioskTheme />
        },
        {
            path: "/prayerRequestDetail",
            element: <TransactionDetail />
        },
        {
            path: "/advertisements",
            element: <Advertisements />
        },
        {
            path: "/donor",
            element: <Donor />
        },
        {
            path: "/donorDetail",
            element: <DonorDetail />
        },
        {
            path: "/changePassword",
            element: <ChangePassword />
        },
        {
            path: "/emailConfig",
            element: <EmailConfig />
        },
        {
            path: "/subscription",
            element: <Subscription />
        },
        // {
        //     path: "/paySubscription",
        //     element: <PaySubscription />
        // },
        {
            path: "/subscriptionDetail",
            element: <SubscriptionDetail />
        },
        {
            path: "/subscribe",
            element: <Subscribe />
        }
    ];

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {token ? <Route path={paymentStatus ? `/dashboard` : '/paySubscription'} element={paymentStatus ? <Dashboard /> : <PaySubscription />}></Route> : <Route path="/login" element={<Login />}></Route>}
                    <Route element={<AdminPrivate token={token} />}>
                        {paymentStatus &&
                            allRoutes?.map((item) => <Route key={item?.path} path={item?.path} element={item?.element}></Route>)}
                        <Route path="/paySubscription" element={<PaySubscription />}></Route>
                        {/* <Route path="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/prayerRequest" element={<Transaction />}></Route>
                        <Route path="/religiousLeader" element={<ReligiousLeader />}></Route>
                        <Route path="/kiosk" element={<Kiosk />}></Route>
                        <Route path="/manageAd" element={<ManageAd />}></Route>
                        <Route path="/viewKioskTheme" element={<ViewKioskTheme />}></Route>
                        <Route path="/prayerRequestDetail" element={<TransactionDetail />}></Route>
                        <Route path="/advertisements" element={<Advertisements />}></Route>
                        <Route path="/donor" element={<Donor />}></Route>
                        <Route path="/donorDetail" element={<DonorDetail />}></Route>
                        <Route path="/changePassword" element={<ChangePassword />}></Route>
                        <Route path="/emailConfig" element={<EmailConfig />}></Route>
                        <Route path="/subscription" element={<Subscription />}></Route>
                        <Route path="/paySubscription" element={<PaySubscription />}></Route>
                        <Route path="/subscriptionDetail" element={<SubscriptionDetail />}></Route> */}
                    </Route>
                    <Route path="*" element={token ? (<Navigate to={paymentStatus ? `/dashboard` : '/paySubscription'} replace />) : (<Navigate to="/login" replace />)}></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default MyRoutes