import { createContext, useContext, useState, useEffect } from 'react';
import { GetFranchisePaymentStatus } from '../services/services';

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {

    const [paymentStatus, setPaymentStatus] = useState(true);
    const setStatus = () => {
        setPaymentStatus(true);
    };

    useEffect(() => {
        const storedpaymentStatus = localStorage.getItem('paymentStatus');
        if (storedpaymentStatus) {
            setPaymentStatus(storedpaymentStatus);
        }
    }, []);
    const getPaymentStatus = async () => {
        const data = await GetFranchisePaymentStatus()
        setPaymentStatus(true)
        if (data?.message === 'subscription not found') {
            setPaymentStatus(false)
        } else {
            setPaymentStatus(true)
        }
    }
    useEffect(() => {
        getPaymentStatus()

    }, [])

    return (
        <PaymentContext.Provider value={{ paymentStatus, setStatus, getPaymentStatus }}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePaymentAuth = () => {
    return useContext(PaymentContext);
};