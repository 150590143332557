import instance from "../instance";

export const SignIn = async (body) => {
    const res = await instance.post('/login', body);
    return res?.data;
}

export const GetAllKiosk = async (body) => {
    const res = await instance.post('/getAllKiosk', body);
    return res?.data;
}

export const GetAllTransaction = async (body) => {
    const res = await instance.post('/getAllTransaction', body);
    return res?.data;
}

export const GetTodayTransaction = async () => {
    const res = await instance.post('/getTodayTransaction');
    return res?.data;
}

export const GetPrayerReq = async (body) => {
    const res = await instance.post('/getPrayerReq', body);
    return res?.data;
}

export const AddReligiousLeader = async () => {
    const res = await instance.post('/addReligiousLeader');
    return res?.data;
}

export const GetConfig = async (body) => {
    const res = await instance.post('/getConfig', body);
    return res?.data;
}

export const GetKioskTheme = async (body) => {
    const res = await instance.post('/getKioskTheme', body);
    return res?.data;
}

export const AssignKioskTheme = async (body) => {
    const res = await instance.post('/assignKioskTheme', body);
    return res?.data;
}

export const GetDonorDetails = async () => {
    const res = await instance.post('/getDonorDetails');
    return res?.data;
}

export const GetDonorTransactions = async (body) => {
    const res = await instance.post('/getDonorTransactions', body);
    return res?.data;
}

export const CreateAdvertisement = async (body) => {
    const res = await instance.post('/createAd', body);
    return res?.data;
}

export const ChangePassword = async (body) => {
    const res = await instance.post('/editPassword', body);
    return res?.data;
}

export const GetLeaderShip = async () => {
    const res = await instance.post('/getReligiousLeader');
    return res?.data;
}

export const GetStats = async (body) => {
    const res = await instance.post('/getStats', body);
    return res?.data;
}

export const AddLeaderShip = async (body) => {
    const res = await instance.post('/addReligiousLeader', body);
    return res?.data;
}

export const UpdateLeaderShip = async (body) => {
    const res = await instance.post('/updateReligiousLeader', body);
    return res?.data;
}


export const GetAllAdvertisement = async (body) => {
    const res = await instance.post('/getAllAd', body);
    return res?.data;
}

export const UpdateAdvertisement = async (body) => {
    const res = await instance.post('/updateAd', body);
    return res?.data;
}

export const DeleteAdvertisement = async (body) => {
    const res = await instance.post('/deleteAd', body);
    return res?.data;
}

export const GetManageAds = async (body) => {
    const res = await instance.post('/getKioskAd', body);
    return res?.data;
}

export const GetDashboardTransaction = async (body) => {
    const res = await instance.post('/getDashBoardTransaction', body);
    return res?.data;
}

export const AssignAdvertisement = async (body) => {
    const res = await instance.post('/assignAd', body);
    return res?.data;
}

export const GetDonationType = async () => {
    const res = await instance.post('/getAllDonation');
    return res?.data;
}

export const GetTransactionDetail = async (body) => {
    const res = await instance.post('/getTranPrayer', body);
    return res?.data;
}

export const GetExcelData = async (body) => {
    const res = await instance.post('/getExcelData', body);
    return res?.data;
}

export const PublishAd = async (body) => {
    const res = await instance.post('/publishAd', body);
    return res?.data;
}

export const PublishAdSocket = async (body) => {
    const res = await instance.post('/publishAdSocket', body);
    return res?.data;
}

export const CreateEmailConfig = async (body) => {
    const res = await instance.post('/emailConf', body);
    return res?.data;
}

export const GetEmailConfig = async (body) => {
    const res = await instance.post('/getEmailConf', body);
    return res?.data;
}

export const UpdateEmailConfig = async (body) => {
    const res = await instance.post('/updateEmailConf', body);
    return res?.data;
}


export const SubscriptionApi = async (body) => {
    const res = await instance.post('/subscription', body);
    return res?.data;
}

export const GetTotalKiosk = async () => {
    const res = await instance.post('/kioskCount');
    return res?.data;
}

export const GetSubscription = async () => {
    const res = await instance.post('/getSubscriptionNew');
    return res?.data;
}

export const GetFranchisePaymentStatus = async () => {
    const res = await instance.post('/getFranchisePaymentStatus');
    return res?.data;
}

export const AddRecurringSubscription = async (body) => {
    const res = await instance.post('/addRecurringSubscription', body);
    return res?.data;
}

export const UpdateRecurringSub = async (body) => {
    const res = await instance.post('/updateRecurringSub', body);
    return res?.data;
}

export const DeleteRecurringSub = async () => {
    const res = await instance.post('/deleteSubscription');
    return res?.data;
}

export const GetRecurringSub = async () => {
    const res = await instance.post('/getRecurringSub');
    return res?.data;
}

export const TransactionGraph = async () => {
    const res = await instance.post('/graphDataDateWise');
    return res?.data;
}