import React, { useState } from 'react'
import { BsEye, BsEyeSlash } from "react-icons/bs"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { loginValidation } from '../../validationSchema';
import logo from '../../assets/images/AppLogo.png';
import { SignIn } from '../../services/services';
import { toast } from 'react-toastify';

const Login = () => {

    const [eyeIcon, setEyeIcon] = useState(true);

    // const handleLogin = () =>
    // {
    //     window.localStorage.setItem("token", true);
    //     window.location.reload();
    // };

    const initialValues = {
        email: '',
        password: '',
    };

    const onSubmit = async (values) => {
        try {
            const data = await SignIn(values)
            if (data?.data?.token) {
                window.localStorage.setItem("token", data?.data?.token);
                window.localStorage.setItem("name", data?.data?.name);
                window.location.reload(true);
            } else if (data?.message === "Wrong Password" || data?.message === "Wrong Email") {
                toast.error("Invalid Credentials")
            } else if (data?.message === "Franchise Block by Admin") {
                toast.error("Super Admin Has Blocked You")
            }
            else {
                toast.error("Some error occured")
            }

        } catch (error) {
            toast.error("Server error")
        }
    };

    return (
        <>
            <div className='w-full flex h-screen bg-primary loginBg font-Poppins '>
                <div className='flex flex-col m-auto h-auto bg-white  justify-center py-10 px-10 rounded-3xl'>
                    <div className='flex justify-center w-full mt-2'>
                        <img className="w-52 object-contain" src={logo} alt="logo" />
                    </div>
                    <div className='flex flex-col mx-auto text-left items-start justify-start'>
                        <div className='text-3xl font-bold text-darkBlack mx-auto text-center w-full pb-2 uppercase mt-10'>Donation Kiosk</div>
                        <div className='text-3xl font-bold text-darkBlack mx-auto text-center w-full pb-2 uppercase '>Admin</div>
                        <div className='text-md text-darkBlack mx-auto text-center w-full'>Enter your email and password to sign in</div>
                    </div>
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginValidation}>
                        <Form>
                            <div className=" flex flex-col mx-auto mt-10 justify-center gap-4 ">
                                <div className='relative flex flex-col w-full'>
                                    <label className='text-darkBlack mb-2 ml-2 font-medium'>Email</label>
                                    <Field autoComplete="off" className='h-8  py-5 pl-3 border-black border rounded-lg bg-white  transition-all duration-1000 text-darkBlack font-semibold  outline-none text-sm  font-Poppins' placeholder='Your Email...' type="text" id="email" name="email" />
                                    <ErrorMessage className='text-error text-sm mt-2 ml-2' name="email" component="div" />
                                </div>
                                <div className='relative flex flex-col mt-5 mb-5 w-full'>
                                    <label className='text-darkBlack mb-2 ml-2 font-medium'>Password</label>
                                    <div className='w-full relative'>
                                        <Field autoComplete="off" className='w-full h-8  py-5 pl-3 border-black border rounded-lg bg-white  transition-all duration-1000 text-darkBlack font-semibold  outline-none text-sm  font-Poppins' placeholder='Your Password...' type={eyeIcon ? "password" : "text"} id="password" name="password" />
                                        <ErrorMessage className='text-error text-sm mt-2 ml-2' name="password" component="div" />
                                        {eyeIcon ?
                                            <BsEyeSlash onClick={() => setEyeIcon(!eyeIcon)} className='absolute text-[#749AA9] right-3 top-3 text-xl cursor-pointer' />
                                            :
                                            <BsEye onClick={() => setEyeIcon(!eyeIcon)} className='absolute text-[#749AA9] right-3 top-3 text-xl cursor-pointer' />
                                        }
                                    </div>
                                    {/* {errors?.password ? <span className='text-red-500 text-sm mt-2 ml-2'>{errors?.password?.message}</span> : null} */}
                                </div>
                                <button type="submit" className='text-white uppercase bg-darkBlack py-2 rounded-xl'>Sign In</button>
                            </div>
                        </Form>
                    </Formik>

                </div>
            </div>
        </>
    )
}
export default Login