import React, { useState, useEffect } from "react";
// import exp from "../../assets/images/exp.png"
import { FcExpired } from "react-icons/fc";

function TokenExpiredModal() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const showHandler = () => {
            setShowModal(true);
        };
        document.addEventListener("showTokenExpiredModal", showHandler);
        return () => {
            document.removeEventListener("showTokenExpiredModal", showHandler);
        };
    }, []);

    const handleLogout = () => {
        localStorage.clear()
        window.location.reload()
    };

    return (
        <div style={{ display: showModal ? "flex" : "none", width: "100%", justifyContent: "center" }} className='flex flex-col justify-center items-center w-full h-full z-[9999] fixed top-0 right-0 bg-[rgba(0,0,0,0.21)]'>
            <div className='text-center bg-white rounded-t-[20px] rounded-b-[20px] md:w-375 w-[95%] '>
                <div className='bg-primary w-full rounded-t-[20px] text-white font-[600] py-3 text-center'>
                    <p>Session Expired</p>
                </div>
                <div className="w-full text-center">
                    <FcExpired size={105} className="text-primary m-auto py-3" />
                    <p className='my-3 text-lg text-gray-600'>Your session has expired. Please login again.</p>
                    {/* <img className="w-150 m-auto py-3" src={exp} alt='' /> */}
                </div>
                <button onClick={handleLogout} className='bg-primary my-5 transition-all ease-in-out hover:scale-[1.05] text-white py-2 px-6 rounded-lg'>Login</button>
            </div>
        </div>
    );
}

export default TokenExpiredModal;
