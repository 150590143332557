import axios from "axios";

const instance = axios.create({
    baseURL: "https://backend.prayoskiosk.com/franchise",
    // baseURL: "https://kjjp4n4d-8080.inc1.devtunnels.ms/franchise",
    headers: {
        "Content-Type": "application/json",
        "access-control-allow-origin": "*",
    },
});

let isTokenExpiredModalVisible = false

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        if (response?.data?.message === "Token is not Valid!" || response?.data?.message === "you are not franchise") {
            // console.log("here")
            if (!isTokenExpiredModalVisible) {
                isTokenExpiredModalVisible = true;
                // Trigger an event to show the modal
                const event = new Event("showTokenExpiredModal");
                document.dispatchEvent(event);
            }
        } else {
            return response
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
